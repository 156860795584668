<script lang="ts" setup>
import type { InputAttributes } from '~ui/types/inputAttributes';
import type { NullableOptions } from '~ui/types/selectOption';

interface Props extends InputAttributes {
  placeholder: string;
  options: NullableOptions[];
  errorMessages?: string[];
  hideLabel?: boolean;
  disabled?: boolean;
  loading?: boolean;
  async?: boolean;
  autoClear?: boolean;
  nullable?: boolean;
  clearable?: boolean;
  customClasses?: string;
  initialLoading?: boolean;
  hideSearchField?: boolean;
  dataTestId?: string;
  isActive?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  errorMessages: () => [],
  hideLabel: false,
  disabled: false,
  loading: false,
  async: false,
  autoClear: true,
  nullable: false,
  clearable: true,
  customClasses: undefined,
  initialLoading: false,
  hideErrorMessage: false,
  hideSearchField: false,
  dataTestId: '',
  isActive: true,
});

const value = defineModel<string | number | null | undefined>({
  required: false,
});
const emit = defineEmits<{
  input: [value: string];
  blur: [event: FocusEvent];
}>();

const HANDLED_KEYS = ['Escape', 'Home', 'End', 'PageUp', 'PageDown', 'ArrowUp', 'ArrowDown'];

const selectedObject = ref<{
  label: string;
  value: string | number | null;
} | null>(null);
const optionsOpen = ref(false);
const searchField = ref('');

const hasError = computed<boolean>(() => !!props.errorMessages.length);
const _hasValue = (v: string | number | null | undefined): boolean => !!v || (props.nullable && v === null);
const hasValue = computed<boolean>(() => _hasValue(value.value));
const shiftedTop = ref<boolean>(false);

const inputState = computed(() => {
  if (props.disabled) {
    return INPUT_STATE_DISABLED;
  }

  if (hasError.value) {
    return INPUT_STATE_ERROR;
  }

  if (hasValue.value) {
    return INPUT_STATE_VALID;
  }

  return INPUT_STATE_DEFAULT;
});

const variantClasses = computed<string>(() => {
  return twMerge(
    cva('flex max-h-[50px] w-full cursor-pointer items-center justify-between rounded-t-lg border p-4 text-lg text-grey-800 outline-none', {
      variants: {
        inputState: {
          [INPUT_STATE_ERROR]: 'border-red-400 text-red-500',
          [INPUT_STATE_VALID]: 'border-green-400 text-primary-500',
          [INPUT_STATE_DEFAULT]: 'border-primary-400',
          [INPUT_STATE_DISABLED]: 'border-grey-400 bg-grey-50 text-grey-500',
        },
        optionsOpen: {
          true: 'border-b-0',
          false: 'rounded-b-lg',
        },
        shiftedTop: {
          true: 'rounded-b-lg',
          false: '',
        },
        isActive: {
          true: 'bg-white',
          false: 'bg-transparent',
        },
      },
    })({
      inputState: inputState.value,
      optionsOpen: optionsOpen.value,
      shiftedTop: shiftedTop.value,
      isActive: props.isActive,
    }),
    props.customClasses,
  );
});

const iconClasses = computed<string>(() => {
  return twMerge(
    cva('text-primary-500', {
      variants: {
        inputState: {
          [INPUT_STATE_ERROR]: 'text-red-500',
          [INPUT_STATE_VALID]: '',
          [INPUT_STATE_DEFAULT]: '',
          [INPUT_STATE_DISABLED]: 'text-grey-500',
        },
      },
    })({
      inputState: inputState.value,
    }),
  );
});

const elementId = useId();
const listboxContainerId = useId();

const selectField = ref<HTMLElement | null>(null);
const clearButton = ref<HTMLButtonElement | undefined>(undefined);
const selectContainer = ref<HTMLElement | null>(null);
const inputRef = ref<HTMLInputElement | undefined>(undefined);
const itemRefs = ref<HTMLElement[] | undefined>(undefined);

const clearSearch = () => {
  if (!props.autoClear) {
    return;
  }
  searchField.value = '';
};

const onChecked = (checkboxObject: { label: string; value: string | number | null }) => {
  // this should store the selected value and not make it as a switch
  value.value = checkboxObject.value;
  selectedObject.value = checkboxObject;

  optionsOpen.value = false;
  clearSearch();
  if (selectField.value) {
    selectField.value.focus();
  }
};

const activePlaceholder = computed(() => {
  return selectedObject.value?.label || props.placeholder;
});

const filteredOptions = computed(() => {
  if (props.async) {
    return props.options;
  }

  if (searchField.value.length) {
    return props.options.filter((option) => option.label.toLowerCase().includes(searchField.value.toLowerCase()));
  }

  return props.options;
});

const focusedOptionIndex = ref(0);
const listControl = (dir: number) => {
  if (focusedOptionIndex.value === 0 && dir === -1) {
    return;
  }
  if (focusedOptionIndex.value === props.options.length - 1 && dir === 1) {
    return;
  }

  focusedOptionIndex.value += dir;

  document.getElementById(`${elementId}-option-${focusedOptionIndex.value}`)?.focus();
};

const toggleOptions = (event: KeyboardEvent, flag: boolean) => {
  if (flag) {
    if (event.shiftKey && event.key === 'Tab') {
      return;
    }
  }
  focusedOptionIndex.value = 0;
  optionsOpen.value = !optionsOpen.value;
  clearSearch();
};

watch(
  () => optionsOpen.value,
  async (newVal) => {
    if (newVal) {
      await nextTick(() => inputRef.value?.focus());
    } else {
      shiftedTop.value = false;
    }
  },
  { immediate: true },
);

watch(
  () => searchField.value,
  (_) => {
    emit('input', _);
  },
);

const onInnerClick = () => {
  if (props.disabled) {
    return;
  }
  optionsOpen.value = !optionsOpen.value;
};

const clear = () => {
  searchField.value = '';
  selectedObject.value = null;
  value.value = props.nullable ? undefined : '';
};

const top = () => {
  if (!optionsOpen.value || !itemRefs.value) {
    return;
  }

  itemRefs.value[0]?.scrollIntoView({ behavior: 'smooth' });
  itemRefs.value[0]?.focus();
};

const bottom = () => {
  if (!optionsOpen.value) {
    return;
  }

  itemRefs.value?.slice(-1).pop()?.scrollIntoView({ behavior: 'smooth' });
  itemRefs.value?.slice(-1).pop()?.focus();
};

const keys = (event: KeyboardEvent) => {
  if (event.code === 'Space' && !optionsOpen.value) {
    event.preventDefault();
    optionsOpen.value = true;
  } else if (HANDLED_KEYS.includes(event.key)) {
    event.preventDefault();

    if (event.key === 'Home' || event.key === 'PageUp') {
      top();
    } else if (event.key === 'End' || event.key === 'PageDown') {
      bottom();
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (!optionsOpen.value) {
        optionsOpen.value = true;
      } else if (event.metaKey) {
        switch (event.key) {
          case 'ArrowUp':
            top();
            break;
          case 'ArrowDown':
            bottom();
            break;
          default:
            break;
        }
      }
    } else if (event.key === 'Escape') {
      optionsOpen.value = false;
      selectField.value?.focus();
    }
  }
};

const handleFocusOut = () => {
  clearSearch();
  optionsOpen.value = false;
};

const onFocusOut = (event: FocusEvent) => {
  if (!selectContainer.value?.contains(<HTMLElement>event.relatedTarget)) {
    handleFocusOut();
    emit('blur', event);
  }
};

watch(
  () => value.value,
  (newVal, oldVal) => {
    // handle clearing
    if (!_hasValue(newVal) && oldVal) {
      clear();
    }
  },
);

const onShifted = (value?: { x: number; y: number }) => {
  shiftedTop.value = !!value && value.y < 0;
};

const findOption = () => props.options.find((o) => o.value === value.value) || null;

onMounted(() => {
  // check if the selected value has already been provided via the model onMounted
  if (value.value !== undefined && !selectedObject.value) {
    // it can be null or something
    selectedObject.value = findOption();
  }
});

const onSearchInputTabbed = (event: KeyboardEvent) => {
  if (!event.shiftKey && itemRefs.value?.length) {
    event.preventDefault();
    itemRefs.value[0]?.focus();
  } else if (event.shiftKey) {
    event.preventDefault();
    handleFocusOut();
    (clearButton.value || selectField.value)?.focus();
  }
};

watch(
  () => props.initialLoading,
  (newVal, oldVal) => {
    if (!newVal && oldVal && value.value && !selectedObject.value) {
      // if loading started initially and has model value
      selectedObject.value = findOption();
    }
  },
  { immediate: true },
);

defineExpose({ clear });
</script>

<template>
  <div ref="selectContainer" class="relative" tabindex="-1" @focusout="onFocusOut" @keydown="keys">
    <UIPopover v-model="optionsOpen" :disabled="disabled" :isDropdown="true" crossAxis placement="bottom-start" @shifted="onShifted">
      <template #anchor>
        <div>
          <UIInputLabel v-if="!hideLabel" :id="elementId" :for="elementId" :label="label" :required="required" />
          <div
            ref="selectField"
            :class="variantClasses"
            :data-testid="dataTestId"
            tabindex="0"
            role="combobox"
            :aria-labelledby="elementId"
            :aria-expanded="optionsOpen"
            :aria-haspopup="true"
            :aria-controls="listboxContainerId"
            @click.stop="onInnerClick"
          >
            <span class="sr-only" aria-live="polite">
              {{
                selectedObject
                  ? $t('general.formFields.searchSelect.helpTextPrefix', {
                      value: selectedObject.label,
                    })
                  : $t('general.formFields.searchSelect.helpTextFallback')
              }}
            </span>
            <div class="mr-2 flex items-center justify-start">
              <UILoader v-if="initialLoading" class="mr-2 fill-secondary-500" size="md" variant="basic" />
              <p>{{ activePlaceholder }}</p>
            </div>
            <div class="flex items-center">
              <button
                v-if="hasValue && clearable && !disabled"
                ref="clearButton"
                :aria-label="
                  $t('general.formFields.searchSelect.buttons.clear', {
                    label: label,
                  })
                "
                class="mr-1 flex size-5 items-center justify-center p-1 hover:rounded-full hover:border hover:border-grey-200 hover:bg-grey-100"
                tabindex="0"
                type="button"
                @click="clear"
                @keydown.space="clear"
              >
                <UIAssetIcon class="flex" path="cross" size="xs" />
              </button>
              <button
                v-show="!initialLoading"
                :aria-label="$t('general.formFields.searchSelect.buttons.openDialog')"
                :class="iconClasses"
                tabindex="-1"
                type="button"
              >
                <UIAssetIcon class="flex" path="chevron-down" size="sm" />
              </button>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <div
          :id="listboxContainerId"
          :class="[shiftedTop ? 'rounded-lg' : 'rounded-b-lg']"
          class="mb-2 min-h-[50px] border bg-white text-lg"
          data-testid="custom-select-box"
        >
          <div
            v-if="!hideSearchField"
            :class="{ 'rounded-t-lg': shiftedTop }"
            class="flex w-full items-center justify-between border border-grey-100"
          >
            <input
              ref="inputRef"
              v-model="searchField"
              :class="{ 'rounded-tl-lg': shiftedTop }"
              :disabled="disabled"
              class="mr-1 size-full border-none p-2 text-primary-500 outline-none"
              placeholder="Search"
              @keydown.tab="onSearchInputTabbed"
            />
            <div v-if="loading" class="flex items-center p-1" data-testid="search-loading">
              <UILoader class="fill-secondary-500" size="md" variant="basic" />
            </div>
          </div>
          <div v-if="filteredOptions.length" role="listbox" class="max-h-64 overflow-y-scroll" aria-activedescendant="">
            <button
              v-for="(option, index) in filteredOptions"
              :id="`${elementId}-option-${index}`"
              :key="`${elementId}-option-${option.value}`"
              ref="itemRefs"
              role="option"
              :tabindex="focusedOptionIndex == index ? 0 : -1"
              class="w-full p-2 text-left duration-100 last:rounded-b-lg focus:bg-secondary-400 focus:text-white"
              :class="[
                value === option.value
                  ? 'bg-secondary-500 text-white hover:bg-secondary-400 hover:text-white'
                  : 'hover:bg-primary-50 focus:bg-primary-50',
              ]"
              type="button"
              :aria-selected="value === option.value"
              @click="onChecked(option)"
              @keydown.up.prevent="listControl(-1)"
              @keydown.down.prevent="listControl(1)"
              @keydown.tab="toggleOptions($event, true)"
            >
              {{ option.label }}
            </button>
          </div>
          <p v-else-if="filteredOptions.length === 0" class="p-2" tabindex="0">
            {{ $t('general.formFields.searchSelect.noResults') }}
          </p>
        </div>
      </template>
    </UIPopover>
    <UIInputErrors v-if="!hideErrorMessage" :errorMessages="errorMessages" :fontSize="errorMessageSize" />
  </div>
</template>
